<script setup lang="ts">
import { useForm } from 'vee-validate'
import { EContent } from 'auth/enums/dialog-contents'
import { recoverPasswordSchema } from 'auth/validation-schema'
import KeyIcon from '@/assets/images/key.svg'

const emit = defineEmits<{
  (e: 'switch', value: EContent): void
}>()

const { defineComponentBinds, handleSubmit, errors, submitCount } = useForm({
  validationSchema: recoverPasswordSchema,
})
const email = defineComponentBinds('email')
const { isSubmitted } = useValidation(submitCount)

const authStore = useAuthStore()
const recover = handleSubmit(async (values) => {
  const isSuccess = await authStore.recoverPassword(values.email)
  if (isSuccess) {
    emit('switch', EContent.RECOVER_PASSWORD_CONFIRM)
  }
})
</script>

<template>
  <form
    class="flex flex-col items-center"
    novalidate
    @submit.prevent="recover"
  >
    <KeyIcon aria-hidden="true" />
    <h2 class="my-8 text-center text-4xl font-normal">Recover Password</h2>
    <div class="flex w-full flex-col items-start">
      <div class="p-float-label w-full">
        <InputText
          id="email"
          v-bind="email"
          type="email"
          :class="['w-full', { 'p-invalid': errors.email && isSubmitted }]"
        />
        <label for="email">Email address</label>
      </div>
      <small
        v-if="errors.email && isSubmitted"
        class="p-error mt-1"
      >
        {{ errors.email }}
      </small>
      <Button
        type="submit"
        class="mt-6 w-full justify-center [&>.p-button-label]:flex-none"
        :disabled="authStore.isProcessing"
        :label="!authStore.isProcessing ? 'Recover' : 'Loading'"
        :icon="authStore.isProcessing ? 'pi pi-spin pi-spinner' : undefined"
      />
    </div>
    <Divider />
    <p class="m-0 inline-flex align-baseline font-normal text-gray-100">
      Back to
      <Button
        label="Log In"
        class="p-button-link ml-1 p-0 align-top font-normal"
        @click="$emit('switch', EContent.SIGN_IN)"
      />
    </p>
  </form>
</template>
